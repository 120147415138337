<template>
  <v-card class="mt-n10 moholand-shadow">
    <v-card-title>
      ایجاد تخفیف جدید
      <v-spacer></v-spacer>

      <v-btn color="primary" :to="destinationRoute">
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />
        <v-row align="center" justify="end" class="mt-8">
          <v-btn @click="clear" depressed>
            پاک کردن فرم
          </v-btn>

          <v-btn
            class="mx-4"
            dark
            color="success"
            :loading="loading"
            type="submit"
          >
            ایجاد تخفیف جدید
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import VFormBase from "vuetify-form-base";
import { formPostPrepare } from "@/constants/PanelFormHandler";
import moment from "jalali-moment";
export default {
  components: {
    VFormBase,
  },
  data() {
    return {
      rules: Constants.rules,
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("create") - 1
        ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      loading: false,
      model: {
        type: "",
        code: "",
        value: "",
        product_id: "",
        course_id: "",
        user_id: "",
        usage_limit: "",
        start_date: "",
        end_date: "",
        usage_per_customer: "",
        status: "",
        percent: "",
        creator_id: this.$store.state.userData?.id || 1,
      },
      schema: {
        type: {
          type: "CustomInput",
          inputType: "select",
          items: Constants.discountTypes,
          itemValue: "id",
          itemText: "value",
          label: "نوع",
          rules: [Constants.rules.required],
          required: true,
        },
        code: {
          type: "CustomInput",
          label: "کد",
          rules: [Constants.rules.required],
          required: true,
        },
        value: {
          type: "CustomInput",
          label: "مقدار",
          rules: [],
          hint: "برای فعال شدن نوع کد تخفیف را تغییر دهید.",
          disabled: true,
          required: true,
          persistentHint: true,
        },
        start_date: {
          type: "ModalDatePicker",
          label: "تاریخ شروع",
          hint: "برای فعال شدن نوع کد تخفیف را تغییر دهید.",
          min: moment(new Date()).format("YYYY/MM/DD"),
          rules: [],
          disabled: true,
          required: true,
          persistentHint: true,
        },
        end_date: {
          type: "ModalDatePicker",
          label: "تاریخ پایان",
          id: 2,
          hint: "برای فعال شدن نوع کد تخفیف را تغییر دهید.",
          min: moment(new Date()).format("YYYY/MM/DD"),
          rules: [],
          disabled: true,
          required: true,
          persistentHint: true,
        },
        percent: {
          type: "CustomInput",
          label: "درصد",
          hint: "برای فعال شدن نوع کد تخفیف را تغییر دهید.",
          rules: [],
          disabled: true,
          required: true,
          persistentHint: true,
        },
        usage_limit: {
          type: "CustomInput",
          label: "محدودیت استفاده",
          required: true,
        },
        usage_per_customer: {
          type: "CustomInput",
          label: "محدودیت استفاده برای هر کاربر",
          required: true,
        },
        product_id: {
          type: "CustomInput",
          inputType: "autocomplete",
          items: [],
          itemValue: "id",
          itemText: "value",
          label: "فایل",
          loading: true,
          required: true,
        },
        course_id: {
          type: "CustomInput",
          inputType: "autocomplete",
          items: [],
          itemValue: "id",
          itemText: "title",
          label: "دوره",
          loading: true,
          required: true,
        },
        user_id: {
          type: "CustomInput",
          inputType: "autocomplete",
          items: [],
          itemValue: "id",
          itemText: "value",
          label: "کاربر",
          loading: true,
          required: true,
        },
        status: {
          type: "CustomInput",
          inputType: "select",
          items: [
            { id: 1, value: "فعال" },
            { id: 2, value: "غیرفعال" },
          ],
          itemValue: "id",
          itemText: "value",
          label: "وضعیت",
          rules: [Constants.rules.required],
          required: true,
        },
      },
    };
  },
  created() {
    this.getUsers();
    this.getCourses();
    this.getProducts();
  },

  methods: {
    getProducts() {
      MyAxios.get("/products/index", {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.schema.product_id = {
            ...this.schema.product_id,
            loading: false,
            items: response.data.map((val) => ({
              id: val.id,
              value: val.name,
            })),
          };
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getCourses() {
      if (!this.schema.course_id.loading) this.schema.course_id.loading = true;

      MyAxios.get("/courses/index", {
        params: {
          conditions: {
            status: 1,
          },
        },
      })
        .then((response) => {
          this.schema.course_id.loading = false;
          this.schema.course_id.items = response.data.data;
        })
        .catch((error) => {
          this.schema.course_id.loading = false;
        });
    },
    getUsers() {
      MyAxios.get("/users/index", {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.schema.user_id = {
            ...this.schema.user_id,
            loading: false,
            items: response.data.map((val) => ({
              id: val.id,
              value: val.first_name + " " + val.last_name,
            })),
          };
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        let data = formPostPrepare(this.model);

        MyAxios.post("discounts/create", data)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "کد تخفیف با موفقیت ایجاد شد.",
              type: "success",
            });
            console.log(response);
            this.$router.replace(this.destinationRoute);
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "مشکلی پیش آمده است. لطفا دوباره سعی کنید",
            });
            console.log(error);
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
    clear() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    "model.start_date"(val) {
      this.model.end_date = "";
      this.schema.end_date = {
        ...this.schema.end_date,
        min: moment(val).format("YYYY/MM/DD"),
      };
    },
    "model.type": function(val) {
      this.model.percent = "";
      this.model.end_date = "";
      this.model.start_date = "";
      this.model.value = "";
      if (val === 1) {
        this.schema.start_date.disabled = true;
        this.schema.percent.disabled = true;
        this.schema.end_date.disabled = true;
        this.schema.value.disabled = false;

        this.schema.start_date.rules = [];
        this.schema.percent.rules = [];
        this.schema.end_date.rules = [];
        this.schema.value.rules = [Constants.rules.required];

        this.schema.start_date.hint =
          "برای فعال شدن نوع کد تخفیف را تغییر دهید.";
        this.schema.end_date.hint = "برای فعال شدن نوع کد تخفیف را تغییر دهید.";
        this.schema.percent.hint = "برای فعال شدن نوع کد تخفیف را تغییر دهید.";
        this.schema.value.hint = "";
      }
      if (val === 2) {
        this.schema.start_date.disabled = false;
        this.schema.percent.disabled = false;
        this.schema.end_date.disabled = false;
        this.schema.value.disabled = true;

        this.schema.start_date.rules = [Constants.rules.required];
        this.schema.percent.rules = [
          Constants.rules.required,
          (v) => (v > 0 && v <= 100) || "درصد کد تخفیف باید بین 0 تا 100 باشد",
        ];
        this.schema.end_date.rules = [Constants.rules.required];
        this.schema.value.rules = [];

        this.schema.start_date.hint = "";
        this.schema.end_date.hint = "";
        this.schema.percent.hint = "";
        this.schema.value.hint = "برای فعال شدن نوع کد تخفیف را تغییر دهید.";
      }
    },
  },
};
</script>

<style></style>
